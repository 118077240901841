
<template>
    <div class="Staking">
        <div class="tips">
            <div class="center connectBoxW">
                <p>
                    <span>NFT Staking shares the same APR with xSPACE pool.</span>
                    <span>APR: {{nftAPR.toFixed(2)}}%</span>
                </p>
            </div>
        </div>
        <div class="tabs">
            <div class="center connectBoxW">
                <div @click="tabIndex = 0;getData(0)">
                    <div :class=" tabIndex == 0 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 0 ? '' : 'no'">Common Planet</p>
                </div>
                <div @click="tabIndex = 1;getData(1)">
                    <div :class=" tabIndex == 1 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 1 ? '' : 'no'">Unique Planet</p>
                </div>
                <div @click="tabIndex = 2;getData(2)">
                    <div :class=" tabIndex == 2 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 2 ? '' : 'no'">Exclusive Star Sign</p>
                </div>
            </div>
        </div>
        <div class="nftList">
            <div v-if="tabIndex == 0" class="center connectBoxW">
                <div class="nodata" v-if="level0list.length == 0">
                    <img class="nodataImg" v-if="!dataLoading" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="!dataLoading" style="color:#feffbc;">No data yet</p>
                    <p v-if="dataLoading" style="color:#feffbc;">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div>
                <ul v-else :class="level0list.length <= 3 ? 'li3' : ''">
                    <li v-for="(item,index) in level0list" :key="index">
                        <div class="nftcard" @click="Details(item)">
                            <div class="nftimg">
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000 && item.stakingRewardSPACE">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0 && item.stakingRewardxSPACE">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="tabIndex == 1" class="center connectBoxW">
                <div class="nodata" v-if="level1list.length == 0">
                    <img class="nodataImg" v-if="!dataLoading" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="!dataLoading" style="color:#feffbc;">No data yet</p>
                    <p v-if="dataLoading" style="color:#feffbc;">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div>
                <ul v-else :class="level1list.length <= 3 ? 'li3' : ''">
                    <li v-for="(item,index) in level1list" :key="index">
                        <div class="nftcard" @click="Details(item)">
                            <div class="nftimg">
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="tabIndex == 2" class="center connectBoxW">
                <div class="nodata" v-if="level2list.length == 0">
                    <img class="nodataImg" v-if="!dataLoading" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="!dataLoading" style="color:#feffbc;">No data yet</p>
                    <p v-if="dataLoading" style="color:#feffbc;">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div>
                <ul v-else :class="level2list.length <= 3 ? 'li3' : ''">
                    <li v-for="(item,index) in level2list" :key="index">
                        <div class="nftcard" @click="Details(item)">
                            <div class="nftimg">
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        <v-Footer></v-Footer>
    </div>
</template>

<script>
import {
    getTokenSymbol,
    getpoolInfo,getpoolLpInfo,gettotalAllocPoint,getstarPerBlock,
    getNFTGroupAmount,NFTGroup,nftUser,nftPendingStar,starMeta,cateURI,nftBoost
} from '../utils/space.js'
import {getFarmApr} from '@/utils/function.js'
export default {
    name: "Staking",
    data() {
        return {
            dataLoading:true,
            userAddress:'',tokenSymbol:'',xtokenSymbol:'',tokenAddress:'',xtokenAddress:'',nftAddress:'',
            pool0Info:{},poolLp0Inof:{},totalAllocPoint:0,startblock:0,nftAPR:0,
            tabIndex: 0,mintToken:0,
            level0list:[{},{},{},{},{},{},{}]
        }
    },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.nftInfoList = window.nftImgData.cardList
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.nftAddress = window.contractAddr.nftAddress
        this.logicalAddress = window.contractAddr.logicalAddress
        this.farmAddress = window.contractAddr.farmAddress
        this.farmLibAddress = window.contractAddr.farmLibAddress
        this.farmPendingAddress = window.contractAddr.farmPendingAddress
        gettotalAllocPoint(this.farmLibAddress).then((allocPoint) => {
            this.totalAllocPoint = allocPoint
        })
        getstarPerBlock(this.farmLibAddress).then((res) => {
            this.startblock = this.$BigNumber(res).div(Math.pow(10,18))
        })
        getpoolInfo(this.farmLibAddress,0,this.userAddress).then(pool0Info => {
            pool0Info.xSPACERate = pool0Info.xRate/10000
            pool0Info.SPACERate = 1 - pool0Info.xRate/10000
            this.pool0Info = pool0Info
        })
        setTimeout(() => {
            this.spacePrice = window.farmData.tokenList[1].price
            nftBoost(this.farmPendingAddress).then(nftboost => {
                //console.log(nftboost)
                var allTokenPrice = ((this.pool0Info.lpSupply/Math.pow(10,18)+this.pool0Info.extraAmount/Math.pow(10,18))*(this.spacePrice))
                this.nftAPR = allTokenPrice == 0 ? '0.00' : getFarmApr(this.startblock,this.pool0Info.allocPoint/this.totalAllocPoint,this.spacePrice,allTokenPrice)*(nftboost/100)
                //console.log(this.nftAPR)
            })
        }, 4000);
        getpoolLpInfo(this.farmLibAddress,0,this.userAddress).then(poolLp0Inof => {
            //console.log(poolLp0Inof)
            this.getlpTokenSymbol(poolLp0Inof.lpAddr).then(symbol => {
                poolLp0Inof.lpOneSymbol = symbol
            })
            this.getlpTokenSymbol(poolLp0Inof.lpTwoAddr).then(symbol => {
                poolLp0Inof.lpTwoSymbol = symbol
            })
            this.getlpTokenSymbol(poolLp0Inof.lpThrAddr).then(symbol => {
                poolLp0Inof.lpThrSymbol = symbol
            })
            this.poolLp0Inof = poolLp0Inof
        })
        this.getData(0)
    },
    methods: {
        Details(item){
            this.$router.push({
                path:'/nftDetail',
                query:{
                    tokenid:item.NFTId,
                    type:2
                }
            })
        },
        getData(level){
            this.dataLoading = true
            this.level0list = []
            this.level1list = []
            this.level2list = []
            getNFTGroupAmount(this.farmAddress,level,this.userAddress).then(stakingNum => {
                //console.log(stakingNum)
                if (stakingNum == 0) {
                    this.dataLoading = false
                }
                for (let i = 0; i < stakingNum; i++) {
                    NFTGroup(this.farmAddress,level,i,this.userAddress).then(NFTId => {
                        //console.log(NFTId)
                        nftUser(this.farmAddress,NFTId,this.userAddress).then(owner => {
                            //console.log(owner)
                            nftPendingStar(this.farmPendingAddress,NFTId,owner).then(peddingStar => {
                                //console.log(peddingStar)
                                starMeta(this.logicalAddress,NFTId).then(nftInfo => {
                                    cateURI(this.nftAddress,NFTId).then(nftUrl => {
                                        var obj = {
                                            nftUrl,
                                            cateId:nftInfo.cateId,
                                            NFTId:Number(NFTId),
                                            name:'',
                                            multiple:Number(nftInfo.multiple/100).toFixed(2),
                                            assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                                            stakingRewardSPACE:Number( peddingStar._amountpendingStar/1e18*this.pool0Info.SPACERate ),
                                            stakingRewardxSPACE:Number( peddingStar._amountpendingStar/1e18*this.pool0Info.xSPACERate ),
                                            stakingRewardLpOne:Number( peddingStar._amountLpPendingStar/1e18 ),
                                            stakingRewardLpTwo:Number( peddingStar._amountLpTwoPendingStar/1e18 ),
                                            stakingRewardLpThr:Number( peddingStar._amountLpThrPendingStar/1e18 ),
                                        }
                                        this.nftInfoList.forEach((item,index) => {
                                            if (item.cid == nftUrl.split('/')[3]) {
                                                obj.name = item.name
                                            }
                                        })
                                        if (nftInfo.level == 0) {
                                            this.level0list.push(obj)
                                            let arr0 = this.level0list
                                            this.dataSort(arr0)
                                        }
                                        if (nftInfo.level == 1) {
                                            this.level1list.push(obj)
                                            let arr1 =  this.level1list
                                            this.dataSort(arr1)
                                        }
                                        if (nftInfo.level == 2) {
                                            this.level2list.push(obj)
                                            let arr2 =  this.level2list
                                            this.dataSort(arr2)
                                        }
                                        this.$forceUpdate()
                                    })
                                })
                            })
                        })
                    })
                        
                }
            })
        },
        dataSort(arr){
            arr.sort((a ,b) => {
                if (a.NFTId > b.NFTId) {
                    return -1
                } else if (a.NFTId < b.NFTId) {
                    return 1
                }
            })
        },
        getlpTokenSymbol(addr){
            return new Promise((resolve, reject) => {
                if (addr == "0x0000000000000000000000000000000000000000") {
                    resolve(localStorage.getItem('nativeCurrencySymbol'))
                } else {
                    getTokenSymbol(addr).then(symbol => {
                        resolve(symbol)
                    })
                }
            })
        },
    }
}
</script>